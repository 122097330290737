body {
  background-color: #1a1c1d;
  color: white;
}

/* TEXT */
.modal-content {
  color: white;
  padding: 20px;
}

.modal-content h2 {
  margin-bottom: 10px;
}

.modal-content i {
  display: block;
  margin-bottom: 10px;
}

.modal-content p {
  margin: 5px 0;
}

.modal-content ul {
  margin-top: 10px;
  padding-left: 20px;
}

.modal-content li {
  margin-bottom: 5px;
}

.modal-content a {
  color: white;
  text-decoration: underline;
}

.modal-content a:hover {
  color: #ddd;
}

/* SCROLL BAR */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  background: #1e1e1e;
  border-radius: 6px;
}

* {
  scrollbar-color: #333 #1e1e1e;
  scrollbar-width: auto;
}

/* GRID CONTAINER */
.grid-container {
  width: 100% !important;
  height: 100vh;
  margin-top: -1%;
  overflow: auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

/* CARD */
.card {
  position: relative;
  overflow: hidden;
}

.card-title {
  z-index: 2;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 100%;
  text-align: center;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  transition: transform 0.5s ease;
}

.card:hover .card-image {
  transform: scale(1.1);
}

/* FILTER BUTTONS */
.filter-button {
  margin-top: 20px;
  background-color: #333;
  color: white;
  border: 2px solid gray;
  padding: 15px;
  margin-right: 10px;
  cursor: pointer;
  height: 40px;
  transition: background-color 0.3s, color 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.filter-button:hover {
  background-color: #555;
  color: #ddd;
}

.filter-button:last-child {
  margin-right: 0;
}

.info-button {
  background-color: #1a1c1d;
  border-radius: 50%;
  font-size: 1.5em;
  border: 4px solid #fff;
  font-weight: bolder;
  padding: 0;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.7);
}