.tier-list {
    display: flex;
    flex-direction: column;
  }
  
  .tier-row {
    display: flex;
    align-items: center;
    background-color: rgb(40, 44, 44);
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
  }
  
  .tier-label-empty {
    position: sticky;
    padding: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
    left: 10px;
    flex: 0 0 50px;
  }

  .tier-label-fill {
    position: absolute;
    padding: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
    width: 50px;
    left: 10px;
  }
  
  .tier {
    position: relative;
  }

  .tier-cards {
    display: grid;
    gap: 10px;
    padding: 10px;
  }
    
  .card-container {
    margin: 5px;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-container .card {
    width: 100%;
    height: 100%;
  }
  
  .card-container.dragging {
    background-color: #282c2c;
  }
  
  .card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .appended-row {
    margin-top: -45px;
    padding-left: 90px;
  }